
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import BaseIcon from '../base/BaseIcon.vue'
import BaseFlyout from '../base/BaseFlyout.vue'
import IOption from '../../shared/general/interfaces/IOption'
import { globalLabelAsString } from '../../shared/general/services/StoreService'

@Component({
  name: 'HeaderLanguageSelection',
  components: {
    BaseIcon,
    BaseFlyout,
  },
})
export default class HeaderLanguageSelection extends Vue {
  @Prop({ required: true }) languages! : IOption[]

  private flyoutVisible : boolean = false

  private selected : IOption = this.languages[0]

  created () {
    this.closeOnOutsideClick()
  }

  mounted () {
    this.selected = this.languages.filter(
      (lang) => (lang.value as string)?.toLowerCase() === this.$store.state.Locale.fsxaLocale.toLowerCase(),
    )?.[0] || this.languages[0]
  }

  /*
 * Sadly, in this case we need the event listener on the document to check if there was an outside click to close
 * the flyout.
 */
  private closeOnOutsideClick () {
    document.addEventListener('click', (e : MouseEvent) => {
      if (this.$el.contains(e.target as Node)) return
      this.flyoutVisible = false
    })
  }

  @Emit('select-language')
  private selectLanguage (language : IOption) : IOption {
    this.flyoutVisible = false
    this.selected = language
    return language
  }

  private toggleFlyout () : void {
    this.flyoutVisible = !this.flyoutVisible
  }

  private get languageLabel () : string {
    return globalLabelAsString('language_label')
  }
}
